import './styles.scss'

import Gumshoe from 'gumshoejs'
import SmoothScroll from 'smooth-scroll'
// GA scroll tracking
window.dataLayer = window.dataLayer || [];

document.addEventListener('DOMContentLoaded', () => {
  const header = document.querySelector('.vhq-main-header')
  const tabBar = document.querySelector('.c-home-tab-bar')
  const lastAnchor = tabBar.querySelector('.c-home-tab-bar__tab:last-child a[href*="#"]')
  const lastAnchorTarget = document.getElementById(lastAnchor.getAttribute('href').match(/#([A-Za-z0-9]+)$/)[1])

  window.addEventListener('scroll', () => {
    // Hide the tab bar when scrolled past the bottom of the last tab's content
    if (window.pageYOffset > (lastAnchorTarget.offsetTop + lastAnchorTarget.getBoundingClientRect().height) - header.getBoundingClientRect().height - tabBar.getBoundingClientRect().height) {
      tabBar.classList.add('c-home-tab-bar--inactive')
    } else {
      tabBar.classList.remove('c-home-tab-bar--inactive')
    }
  })

  Gumshoe('.c-home-tab-bar__tab a', {
    offset: () => header.getBoundingClientRect().height + tabBar.getBoundingClientRect().height + 100,
    reflow: true // watch for changes in the layout of content
  })

  // Listen for activate events and trigger GA scroll tracking code
  document.addEventListener('gumshoeActivate', function (event) {
    // The link
    const link = event.detail.link;
    const linkText = link.querySelector('.full-title').innerHTML;
    window.dataLayer.push({
      'event': 'scrollToView',
      'eventInfo': {
        'category': 'Page interactions',
        'action': 'scrolled to view',
        'label': linkText,
        'label2': undefined
      }
    });
  }, false);

  SmoothScroll('.c-home-tab-bar__tab a[href*="#"]', {
    speed: 500, // amount of time in milliseconds it should take to scroll 1000px
    durationMax: 1000,
    offset: (anchor) => {
      // leave a gap between the bottom of the tab bar and top of the content
      const gap = 10

      // scroll to top of target element's margin
      const marginAdjustment = (window.getComputedStyle && window.getComputedStyle(anchor).marginTop)
        ? parseInt(window.getComputedStyle(anchor).marginTop, 10)
        : 0

      return header.getBoundingClientRect().height + tabBar.getBoundingClientRect().height + marginAdjustment + gap
    },
    updateURL: true // update the URL on scroll
  })
})
