/* eslint-disable import/first */

import LazyLoad from 'vanilla-lazyload';
import jQuery from 'jquery'
window.$ = window.jQuery = jQuery

import '../../layout'
import './styles.scss'

import './HomeHero'
import './Credibility'
import './TabBar'
import './ProductFeature'
import './CustomerStory'
import './CustomerQuotes'
import './Trust'
import './Demo'
import './LogoBar'
import './RetailSuccess'
import './Spacer'
import '../../refresh/components/footer/TrialCTA'
import '../../refresh/components/body/VendReviews'
import '../../refresh/components/pure/InPlaceVideo'
import '../../refresh/components/pure/PlayButton'
import '~templates/components/organisms/heroTagBanner'

document.addEventListener('DOMContentLoaded', () => {
  const lazyLoadInstance = new LazyLoad({ // eslint-disable-line
    elements_selector: '.js-lazy',
    unobserve_entered: true,
    threshold: 0
  });
});
