import './styles.scss'
import '../../../refresh/components/pure/Button/styles.scss'

import VisSenseFactory from 'vissense'

// requires window when initialised from CommonJS with WebPack,
// see https://github.com/vissense/vissense/issues/35
const VisSense = VisSenseFactory(window)

document.addEventListener('DOMContentLoaded', () => {
  Array.from(document.querySelectorAll('.c-product-feature__video')).forEach((videoEl) => {
    const videoMonitor = VisSense(videoEl, {
      fullyvisible: 0.67,
      hidden: 0.33
    }).monitor({
      visible: () => {
        if (videoEl.readyState < 2) {
          // start loading video when partially visible, if not already loaded
          videoEl.load()
        }
      },
      fullyvisible: () => {
        videoEl.play()
      },
      hidden: () => {
        videoEl.pause()
      }
    })
    videoMonitor.start()
  })

  Array.from(document.querySelectorAll('.c-product-feature__background .animated-bg')).forEach((el) => {
    const animMonitor = VisSense(el, {
      fullyvisible: 1,
      hidden: 0
    }).monitor({
      visible: () => {
        el.classList.add('animate')
      },
      fullyvisible: () => {},
      hidden: () => {
        el.classList.remove('animate')
      }
    })
    animMonitor.start()
  })

  if (window.MultiOutletMapAnimation) {
    const mapAnimation = new window.MultiOutletMapAnimation()
    mapAnimation.init()

    const animMonitor = VisSense(document.querySelector('.c-product-feature--multiOutlet'), {
      fullyvisible: 1,
      hidden: 0
    }).monitor({
      visible: () => {
        mapAnimation.play()
      },
      fullyvisible: () => {},
      hidden: () => {
        mapAnimation.pause()
      }
    })
    animMonitor.start()
  }
})
